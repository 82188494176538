import React from 'react';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import Grid from '@mui/material/Grid2';
import { TableFooter, Tooltip } from '@mui/material';
import Box from '@mui/material/Box';
import ErrorMessage from '../../../../common/ErrorMessage';
import {
  ProjectBuildEventDialog_ConsumptionRecordsFragment,
  ProjectBuildEventDialog_TestSeriesConsumptionFragment,
  useProjectBuildEventDialog_ComparisonQuery,
} from '../../../../../generated/graphql';
import { TableWithVerticalDividers } from '../../../../common/tables/TableWithVerticalDividers';
import { TableWithBorder } from '../../../../common/tables/TableWithBorder';

interface Props {
  projectBuildEvent: {
    id: string
    plant?: {
      plantCode: number
    } | null
    adminEndWeek: number
    adminStartWeek: number
    buildEndWeek: number
    buildStartWeek: number
    productionStart?: number | null
  },
}

interface NormalizedData {
  key: string;
  typeCode?: string | null;
  pmlId?: string | null;
  partNumber?: string | null;
  quantity?: number | null;
  post: number;
  fgPos?: string | null;
  type: string;
}

export function ProjectBuildEventCompare(props: Props) {
  const { data, loading, error } = useProjectBuildEventDialog_ComparisonQuery({
    variables: {
      projectBuildEventId: props.projectBuildEvent.id,
      plantCode: props.projectBuildEvent.plant?.plantCode || 0,
      // Optional because we currently don't have this data. Should work when we do.
      sopWeek: props.projectBuildEvent.productionStart || undefined, // optional
    },
    skip: !props.projectBuildEvent.buildEndWeek,
  });

  if (loading) {
    return <>Loading...</>;
  }

  if (error) {
    return <ErrorMessage error={error} />;
  }

  if (!data) {
    return null;
  }

  const normalizedTcrs: NormalizedData[] = data.testSeriesConsumptions
    .flatMap((n) => (
      n.usageRules.map((ur) => {
        const partNumber = n.partVersion?.preSeriesNumber?.toString()
          || n.part?.partNumber
          || n.softwarePart?.partNumber
          || n.deliveryModule?.partNumber;

        const { typeCode } = n;
        const fgPos = n.partAddress?.fgPos?.number;

        return {
          key: `${typeCode}-${ur.pmlId}-${partNumber}-${fgPos}-${n.quantity}-${n.POST}`,
          typeCode: n.typeCode,
          pmlId: ur.pmlId,
          partNumber,
          quantity: n.quantity,
          post: n.POST,
          fgPos,
          type: testSeriesConsumptionType(n),
        };
      })
    ));

  const normalizedCRs: NormalizedData[] = data.consumptionRecords.flatMap((n) => (
    n.usageRules.map((ur) => {
      const partNumber = n.partUsage?.part?.partNumber
        || n.partUsage?.softwarePart?.partNumber
        || n.partUsage?.deliveryModule?.partNumber;

      const typeCode = n.mbom?.ebom.productContext?.typeCode;
      const fgPos = n.partUsage?.partAddress?.fgPos?.number;

      return {
        key: `${typeCode}-${ur.pmlId}-${partNumber}-${fgPos}-${n.quantity}-${n.POST}`,
        typeCode,
        pmlId: ur.pmlId,
        partNumber,
        quantity: n.quantity,
        post: n.POST,
        type: consumptionRecordType(n),
        fgPos,
      };
    })
  ));

  normalizedCRs.sort(sortRows);
  normalizedTcrs.sort(sortRows);

  const both = normalizedTcrs.filter((tsr) => normalizedCRs.some((cr) => cr.key === tsr.key));
  const onlyTcrs = normalizedTcrs.filter((tsr) => !both.some((b) => b.key === tsr.key));
  const onlyCRs = normalizedCRs.filter((cr) => !both.some((b) => b.key === cr.key));

  return (
    <>
      <Grid container spacing={2}>
        <Grid size={{ xs: 12, lg: 8 }}>
          <TableWithVerticalDividers>
            <TableHead>
              <TableRow>
                <TableCell>Mismatched TSC</TableCell>
                <TableCell>Mismatched CR</TableCell>
                <TableCell>Matched</TableCell>
                <TableCell>Plant</TableCell>
                <TableCell>productionStart</TableCell>
                <TableCell>buildStartWeek</TableCell>
                <TableCell>buildEndWeek</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              <TableRow>
                <TableCell>{onlyTcrs.length}</TableCell>
                <TableCell>{onlyCRs.length}</TableCell>
                <TableCell>{both.length}</TableCell>
                <TableCell>{props.projectBuildEvent.plant?.plantCode}</TableCell>
                <TableCell>{props.projectBuildEvent.productionStart}</TableCell>
                <TableCell>{props.projectBuildEvent.buildStartWeek}</TableCell>
                <TableCell>{props.projectBuildEvent.buildEndWeek}</TableCell>
              </TableRow>
            </TableBody>
          </TableWithVerticalDividers>
        </Grid>

        <Grid size={{ xs: 12, lg: 6 }}>
          <h3>Mismatched Test Series Consumptions</h3>
          <ComparisonTable data={onlyTcrs} />
        </Grid>
        <Grid size={{ xs: 12, lg: 6 }}>
          <h3>Mismatched Consumption Records</h3>
          <ComparisonTable data={onlyCRs} />
        </Grid>
        <Grid size={{ xs: 12, lg: 8 }}>
          <h3>Matches</h3>
          <ComparisonTable data={both} />
        </Grid>
      </Grid>
    </>
  );
}

function consumptionRecordType(n: ProjectBuildEventDialog_ConsumptionRecordsFragment) {
  if (n.partUsage.part) {
    return 'Part';
  }

  if (n.partUsage.softwarePart) {
    return 'SoftwarePart';
  }

  if (n.partUsage.deliveryModule) {
    return 'DeliveryModule';
  }

  return '';
}

function testSeriesConsumptionType(n: ProjectBuildEventDialog_TestSeriesConsumptionFragment) {
  if (n.partVersion) {
    return 'PartVersion';
  }

  if (n.part) {
    return 'Part';
  }

  if (n.softwarePart) {
    return 'SoftwarePart';
  }

  if (n.deliveryModule) {
    return 'DeliveryModule';
  }

  return '';
}

function ComparisonTable(props: { data: NormalizedData[] }) {
  return (
    <TableWithBorder>
      <TableHead>
        <TableRow>
          <TableCell>typeCode</TableCell>
          <TableCell>fgPos</TableCell>
          <TableCell>post</TableCell>
          <TableCell>pmlId</TableCell>
          <TableCell>partNumber</TableCell>
          <TableCell>quantity</TableCell>
          <TableCell>type*</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {props.data.map((n) => (
          <TableRow key={n.key}>
            <TableCell>{n.typeCode}</TableCell>
            <TableCell>{n.fgPos}</TableCell>
            <TableCell>{n.post}</TableCell>
            <TableCell>{n.pmlId}</TableCell>
            <TableCell>{n.partNumber}</TableCell>
            <TableCell>{n.quantity}</TableCell>
            <TableCell>{n.type}</TableCell>
          </TableRow>
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <TableCell colSpan={7}>
            <Box display="flex" justifyContent="flex-end">
              * type is not part of comparison
            </Box>
          </TableCell>
        </TableRow>
      </TableFooter>
    </TableWithBorder>
  );
}

/**
 * Sort by fgPos and then POST
 */
function sortRows(a: NormalizedData, b: NormalizedData) {
  if (a.fgPos === b.fgPos) {
    return a.post - b.post;
  }

  return a.fgPos?.localeCompare(b.fgPos || '') || 0;
}
